<ng-container *ngIf="cookieSocialMedia; else noCookies">
  <iframe
    [src]="sanitizedUrl"
    allowtransparency="true"
    frameborder="0"
    scrolling="no"
    class="player_embed"
    name="player_embed"
    allowfullscreen
    mozallowfullscreen
    webkitallowfullscreen
    oallowfullscreen
    msallowfullscreen
  ></iframe>
</ng-container>

<ng-template #noCookies>
  <div class="movie__no-cookies"
    ><div>{{ this.ERROR_MESSAGE }}</div>
    <div (click)="openCookieConsent()" class="no-cookies__btn">Open cookievoorkeuren</div></div
  >
</ng-template>
