import { InputBooleanAttribute, isTruthyInput } from '../empty-attribute';

export const DEFAULT_TYPEAHEAD_BUFFER_TIME = 200;

export interface TypeaheadControllerProperties {
  getItems: () => TypeaheadItem[];
  typeaheadBufferTime: number;
  active: boolean;
}

export interface TypeaheadItem {
  typeaheadText: string;
  tabIndex: number;
  disabled: InputBooleanAttribute;
  focus: () => void;
}

type TypeaheadRecord = [number, TypeaheadItem, string];

export const TYPEAHEAD_RECORD = {
  INDEX: 0,
  ITEM: 1,
  TEXT: 2,
} as const;

export class TypeaheadController {
  private typeaheadRecords: TypeaheadRecord[] = [];

  private typeaheadBuffer = '';

  private cancelTypeaheadTimeout: ReturnType<typeof setTimeout>;

  isTypingAhead = false;

  lastActiveRecord: TypeaheadRecord | null = null;

  constructor(private readonly getProperties: () => TypeaheadControllerProperties) {}

  private get items() {
    return this.getProperties().getItems();
  }

  private get active() {
    return this.getProperties().active;
  }

  readonly onKeydown = (event: KeyboardEvent) => {
    if (this.isTypingAhead) {
      this.typeahead(event);
    } else {
      this.beginTypeahead(event);
    }
  };

  private beginTypeahead(event: KeyboardEvent) {
    if (!this.active) return;

    if (event.code === 'Space' || event.code === 'Enter' || event.code.startsWith('Arrow') || event.code === 'Escape') {
      return;
    }

    this.isTypingAhead = true;

    this.typeaheadRecords = this.items.map((el, index) => [index, el, el.typeaheadText.trim().toLowerCase()]);
    this.lastActiveRecord =
      this.typeaheadRecords.find((record) => record[TYPEAHEAD_RECORD.ITEM].tabIndex === 0) ?? null;
    if (this.lastActiveRecord) {
      this.lastActiveRecord[TYPEAHEAD_RECORD.ITEM].tabIndex = -1;
    }

    this.typeahead(event);
  }

  private typeahead(event: KeyboardEvent) {
    if (event.defaultPrevented) return;

    clearTimeout(this.cancelTypeaheadTimeout);

    if (event.code === 'Enter' || event.code.startsWith('Arrow') || event.code === 'Escape') {
      this.endTypeahead();
      if (this.lastActiveRecord) {
        this.lastActiveRecord[TYPEAHEAD_RECORD.ITEM].tabIndex = -1;
      }
      return;
    }

    if (event.code === 'Space') {
      event.preventDefault();
    }

    this.cancelTypeaheadTimeout = setTimeout(this.endTypeahead.bind(this), this.getProperties().typeaheadBufferTime);

    this.typeaheadBuffer += event.key.toLowerCase();

    const lastActiveIndex = this.lastActiveRecord ? this.lastActiveRecord[TYPEAHEAD_RECORD.INDEX] : -1;
    const numRecords = this.typeaheadRecords.length;

    const rebaseIndexOnActive = (record: TypeaheadRecord) => {
      return (record[TYPEAHEAD_RECORD.INDEX] - lastActiveIndex + numRecords) % numRecords;
    };

    const matchingRecords = this.typeaheadRecords
      .filter(
        (record) =>
          !isTruthyInput(record[TYPEAHEAD_RECORD.ITEM].disabled) &&
          record[TYPEAHEAD_RECORD.TEXT].startsWith(this.typeaheadBuffer)
      )
      .sort((a, b) => rebaseIndexOnActive(a) - rebaseIndexOnActive(b));

    if (matchingRecords.length === 0) {
      clearTimeout(this.cancelTypeaheadTimeout);
      if (this.lastActiveRecord) {
        this.lastActiveRecord[TYPEAHEAD_RECORD.ITEM].tabIndex = -1;
      }
      this.endTypeahead();
      return;
    }

    const isNewQuery = this.typeaheadBuffer.length === 1;
    let nextRecord: TypeaheadRecord;

    if (this.lastActiveRecord === matchingRecords[0] && isNewQuery) {
      nextRecord = matchingRecords[1] ?? matchingRecords[0];
    } else {
      nextRecord = matchingRecords[0];
    }

    if (this.lastActiveRecord) {
      this.lastActiveRecord[TYPEAHEAD_RECORD.ITEM].tabIndex = -1;
    }

    this.lastActiveRecord = nextRecord;
    nextRecord[TYPEAHEAD_RECORD.ITEM].tabIndex = 0;
    nextRecord[TYPEAHEAD_RECORD.ITEM].focus();
    return;
  }

  private endTypeahead() {
    this.isTypingAhead = false;
    this.typeaheadBuffer = '';
    this.typeaheadRecords = [];
  }
}
