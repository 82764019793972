import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { defer, fromEvent, merge, Observable, Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { FocusState, isClosableKey, isSelectableKey, NavigableKeys } from '../core';
import { InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';
import { ActiveDescendantListController } from '../core/navigation/activedescendant-list.controller';
import { SelectableListController } from '../core/selection/selectable-list.controller';
import { DEFAULT_TYPEAHEAD_BUFFER_TIME, TypeaheadController } from '../core/typeahead';
import { Corner } from '../surface/surface-position-properties.interface';
import { SurfacePositionController } from '../surface/surface-position.controller';
import { MenuItemComponent, MenuItemInterface, MenuItemSelectionChange } from './menu-item/menu-item.component';

let _uniqueIdCounter = 0;

function getFocusedElement(activeDoc: Document | ShadowRoot = document): HTMLElement | null {
  let activeEl = activeDoc.activeElement as HTMLElement | null;

  while (activeEl && activeEl?.shadowRoot?.activeElement) {
    activeEl = activeEl.shadowRoot.activeElement as HTMLElement | null;
  }

  return activeEl;
}

export class MenuSelectionChange<T = any> {
  constructor(
    public selected: MenuItemInterface<T>[],
    public added: MenuItemInterface<T>[],
    public removed: MenuItemInterface<T>[]
  ) {}
}

export interface MenuItemParentInterface {
  multiple: boolean;
  checkbox?: boolean;
}
export const CDS_MENU_ITEM_PARENT = new InjectionToken<MenuItemParentInterface>('cds-menu-item-parent');

@Component({
  selector: 'campus-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnChanges, AfterContentInit, AfterViewInit, OnDestroy, MenuItemParentInterface {
  @ContentChildren(forwardRef(() => MenuItemComponent), { descendants: true }) items: QueryList<MenuItemInterface>;

  //#region DI
  private element = inject(ElementRef).nativeElement;
  private _cd = inject(ChangeDetectorRef);
  private _ngZone = inject(NgZone);
  //#endregion

  //#region Private Fields
  private _lastFocusedElement: HTMLElement;
  private _isRepositioning = false;

  private _destroyed$ = new Subject<void>();

  private readonly itemsSelectionChanges: Observable<MenuItemSelectionChange> = defer(() => {
    const items = this.items;

    if (items) {
      return items.changes.pipe(
        startWith(items),
        switchMap(() => merge(...items.map((option) => option.selectionChange)))
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.itemsSelectionChanges)
    );
  }) as Observable<MenuItemSelectionChange>;

  private readonly menuPositionController = new SurfacePositionController(() => {
    return {
      anchorCorner: this.anchorCorner,
      surfaceCorner: this.menuCorner,
      surfaceEl: this.element,
      anchorEl: this._getSurfaceAnchorElement(),
      positioning: this.positioning === 'popover' ? 'document' : this.positioning,
      isOpen: this.open,
      xOffset: this.xOffset,
      yOffset: this.yOffset,
      repositionStrategy: this.hasOverflow && this.positioning !== 'popover' ? 'move' : 'resize',
      onOpen: this._onOpened.bind(this),
      beforeClose: this._beforeClose.bind(this),
      onClose: this._onClosed.bind(this),
    };
  });

  //#endregion

  //#region Inputs
  @HostBinding('attr.role')
  @Input()
  role = 'menu';

  @Input() anchor: TemplateRef<Component> | ElementRef | HTMLElement;

  @Input() anchorSelector: string;

  @Input() positioning: 'absolute' | 'fixed' | 'document' | 'popover' = 'absolute';

  @Input() hasOverflow: InputBooleanAttribute;

  @Input() open: boolean;

  @Input() xOffset = 0;

  @Input() yOffset = 0;

  @Input() anchorCorner: Corner = Corner.END_START;

  @Input() menuCorner: Corner = Corner.START_START;

  @Input() stayOpenOnClick: InputBooleanAttribute;

  @Input() stayOpenOnOutsideClick: InputBooleanAttribute;

  @Input() stayOpenOnFocusout: InputBooleanAttribute;

  @Input() stayOpenOnAnchorClick: InputBooleanAttribute;

  @Input() skipRestoreFocus: InputBooleanAttribute = false;

  @Input() stopClickPropagationWhenOpen: InputBooleanAttribute = false;

  @Input() allowDownToOpen = false;

  @Input() defaultFocus: FocusState = FocusState.FIRST_ITEM;

  @HostBinding('attr.aria-multiselectable')
  @Input()
  multiple = false;

  @Input() sortComparator: (a: MenuItemInterface, b: MenuItemInterface, options: MenuItemInterface[]) => number;

  @Input() compareWith: (o1: any, o2: any) => boolean;

  @Input()
  selectableController: SelectableListController<MenuItemInterface>;
  @Input()
  navigableController: ActiveDescendantListController<MenuItemInterface>;
  @Input()
  typeaheadController: TypeaheadController;

  @Input() typeaheadDelay = DEFAULT_TYPEAHEAD_BUFFER_TIME;
  @Input() typeaheadActive = true;

  //#endregion

  //#region Outputs
  @Output() selectionChange = new EventEmitter<MenuSelectionChange>();
  @Output() openChange = new EventEmitter<boolean>();
  //#endregion

  //#region Host Bindings
  @HostBinding('attr.id')
  id = `cds-menu-${_uniqueIdCounter++}`;

  @HostBinding('attr.popover')
  popover: string;

  @HostBinding('attr.aria-hidden')
  ariaHidden = true;

  @HostBinding('class')
  defaultClasses = [
    'cds-menu',

    'hidden',
    // 'block',

    'corner-s',
    'elevation-4',
    'inset-auto',
    'border-none',
    'p-0',
    'color-inherit',
    'absolute',
    'overflow-visible',
    'user-select-none',
    'max-h-xs',
    'h-inherit',
    'w-max',

    'index-overlay',
  ];

  //#endregion

  //#region Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      if (this.open) {
        this.show();
        this._setUpGlobalEventListeners();
      } else {
        this.close();

        this._cleanUpGlobalEventListeners();
      }
    }
    if (changes.positioning) {
      this.popover = this.positioning === 'popover' ? 'manual' : undefined;
      this._cd.markForCheck();
    }

    // Firefox does not support popover. Fall-back to using fixed.
    if (
      changes.positioning &&
      changes.positioning.currentValue === 'popover' &&
      !(this._getAnchorElement() as unknown as { showPopover?: () => void }).showPopover
    ) {
      this.positioning = 'fixed';
    }

    if (changes.skipRestoreFocus) {
      this.skipRestoreFocus = isTruthyInput(this.skipRestoreFocus);
    }
    if (changes.stopClickPropagationWhenOpen) {
      this.stopClickPropagationWhenOpen = isTruthyInput(this.stopClickPropagationWhenOpen);
    }
  }

  ngAfterViewInit() {
    const anchorElement = this._getAnchorElement();
    anchorElement.setAttribute('aria-haspopup', 'listbox');
    anchorElement.setAttribute('aria-expanded', 'false');
    anchorElement.setAttribute('aria-controls', this.id);

    merge(fromEvent(this.element, 'keydown', { capture: true }), fromEvent(anchorElement, 'keydown', { capture: true }))
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: KeyboardEvent) => {
        if ((event.target as HTMLElement).hasAttribute('data-close-menu')) {
          this.close();
          return;
        }
        if (!event.defaultPrevented) {
          const closableAltKey =
            this.open &&
            event.altKey &&
            [NavigableKeys.ArrowDown.toString(), NavigableKeys.ArrowUp.toString()].includes(event.code);
          if (
            !this.open &&
            (isSelectableKey(event.code) || (this.allowDownToOpen && event.code === NavigableKeys.ArrowDown))
          ) {
            event.preventDefault();
            this.show();
          } else if (isClosableKey(event.code) || closableAltKey) {
            event.preventDefault();
            this.close();
          } else {
            this.typeaheadController.onKeydown(event);
          }
        }
      });

    merge(fromEvent(this.element, 'keydown'), fromEvent(this._getAnchorElement(), 'keydown'))
      .pipe(takeUntil(this._destroyed$))
      .subscribe(this.navigableController.onKeydown.bind(this.navigableController));
  }

  ngAfterContentInit(): void {
    fromEvent(document, 'click', { capture: true })
      .pipe(takeUntil(this._destroyed$))
      .subscribe(this._onDocumentClick.bind(this));

    this._initNavigableController();
    this._initTypeaheadController();

    if (this.selectableController) return;

    this._initSelectableController();
    this.items.changes.pipe(startWith(null), takeUntil(this._destroyed$)).subscribe(() => {
      this._resetOptions();
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
  //#endregion

  //#region Public API
  show() {
    this.open = true;
    this.ariaHidden = undefined;
    this.menuPositionController.update();
    this._getAnchorElement().querySelector('.state-layer')?.setAttribute('data-state', 'press');
    this.openChange.emit(true);
    this._cd.markForCheck();
  }

  close() {
    this.open = false;
    this.ariaHidden = true;
    this.menuPositionController.update();
    this.openChange.emit(false);
    this._getAnchorElement().querySelector('.state-layer')?.removeAttribute('data-state');
    this._cd.markForCheck();
  }
  //#endregion

  //#region Surface Position Controller Callbacks
  private _onOpened() {
    this._lastFocusedElement = getFocusedElement();

    const { activeItem } = this.navigableController;
    this._sortValues();
    const selection = this.selectableController.selected;

    if (activeItem && this.defaultFocus !== FocusState.NONE) {
      activeItem.setInactive();
    }

    if (selection.length > 0) {
      this.navigableController.setActiveItem(selection[0]);
    } else {
      switch (this.defaultFocus) {
        case FocusState.FIRST_ITEM:
          this.navigableController.setFirstItemActive();
          break;
        case FocusState.LAST_ITEM:
          this.navigableController.setLastItemActive();
          break;
        case FocusState.LIST_ROOT:
          this.element.focus();
          break;
        default:
        case FocusState.NONE:
          // Do nothing.
          break;
      }
    }

    this._cd.markForCheck();
  }

  private _beforeClose() {
    this.open = false;

    if (!this.skipRestoreFocus) {
      this._lastFocusedElement?.focus?.();
    }

    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  private _onClosed() {
    this.open = false;
  }
  //#endregion

  private _resetOptions() {
    const changedOrDestroyed = merge(this.items.changes, this._destroyed$);

    if (this.selectableController) {
      this.selectableController.setSelection(...this.items.toArray().filter((item) => item.selected));
    }
    this.itemsSelectionChanges.pipe(takeUntil(changedOrDestroyed)).subscribe((event) => {
      this._onSelect(event.source, event.isUserInput);

      if (event.isUserInput && !this.multiple && this.open && !isTruthyInput(this.stayOpenOnClick)) {
        this.close();
        this.element.focus();
      }
    });
  }

  private _onWindowResize() {
    if (
      this._isRepositioning ||
      (this.positioning !== 'document' && this.positioning !== 'fixed' && this.positioning !== 'popover')
    ) {
      return;
    }
    this._isRepositioning = true;
    this._reposition();
    this._isRepositioning = false;
  }

  private _reposition() {
    if (this.open) {
      this.menuPositionController.position();
    }
  }

  private _setUpGlobalEventListeners() {
    document.addEventListener('resize', this._onWindowResize.bind(this), { passive: true });
    window.addEventListener('resize', this._onWindowResize.bind(this), { passive: true });
  }

  private _cleanUpGlobalEventListeners() {
    document.removeEventListener('resize', this._onWindowResize.bind(this));
    window.removeEventListener('resize', this._onWindowResize.bind(this));
  }

  private _onSelect(item: MenuItemInterface, isUserInput: boolean): void {
    const wasSelected = this.selectableController.isSelected(item);

    if (item.value == null && !this.multiple) {
      item.deselect();
      this.selectableController.clear();
    } else {
      if (wasSelected !== item.selected) {
        if (!this.multiple) {
          this.selectableController.clear();
        }
        if (item.selected) this.selectableController.select(item);
        else this.selectableController.deselect(item);
      }

      if (isUserInput) {
        this.navigableController.setActiveItem(item);
      }

      if (this.multiple) {
        this._sortValues();

        if (isUserInput) {
          this.element.focus();
        }
      }
    }
  }

  private _sortValues() {
    if (this.multiple) {
      const items = this.items.toArray();

      this.selectableController.sort((a, b) => {
        return this.sortComparator ? this.sortComparator(a, b, items) : items.indexOf(a) - items.indexOf(b);
      });
    }
  }

  private _onDocumentClick(event: Event) {
    const path = event.composedPath();

    const clickedOnMenuOpenBlocker =
      path.filter((el) => (el as HTMLElement)?.hasAttribute?.('data-keep-closed')).length > 0;

    const clickedOnAnchor = path.includes(this._getAnchorElement());

    const clickedOnSurface = path.includes(this.element);

    const closeOnOutsideClick = [
      !isTruthyInput(this.stayOpenOnOutsideClick),
      !clickedOnAnchor,
      !clickedOnSurface,
    ].every(Boolean);

    const closeOnAnchorClick = clickedOnAnchor && !isTruthyInput(this.stayOpenOnAnchorClick);

    if (this.open && this.stopClickPropagationWhenOpen && !(clickedOnAnchor || clickedOnSurface)) {
      event.stopPropagation();
      this.close();
      return;
    }

    if (!this.open && clickedOnAnchor && !clickedOnMenuOpenBlocker) {
      this.show();
      return;
    }
    if (!this.open) {
      return;
    }

    if (closeOnOutsideClick || clickedOnMenuOpenBlocker || closeOnAnchorClick) {
      this.skipRestoreFocus = true;
      this.close();
      this.skipRestoreFocus = false;
    }
  }

  private _initNavigableController(): void {
    if (!this.navigableController) {
      this.navigableController = new ActiveDescendantListController(this.items);
    }

    this.navigableController.tabOut.pipe(takeUntil(this._destroyed$)).subscribe((event: KeyboardEvent) => {
      if (this.open && !isTruthyInput(this.stayOpenOnFocusout)) {
        // Restore focus to the trigger before closing. Ensures that the focus
        // position won't be lost if the user got focus into the overlay.
        this.element.focus();
        this.close();
        event.preventDefault();
      }
    });
  }

  private _initSelectableController(): void {
    if (!this.selectableController) {
      const compareWith = this.compareWith || ((o1: any, o2: any) => o1 === o2);
      this.selectableController = new SelectableListController(
        this.multiple,
        this.items?.toArray().filter((item) => item.selected),
        true,
        compareWith
      );

      this.selectableController.changed.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
        this._sortValues();
        event.added.forEach((option) => option.select());
        event.removed.forEach((option) => option.deselect());
        this.selectionChange.emit(new MenuSelectionChange(event.selected, event.added, event.removed));
      });
    }
  }

  private _initTypeaheadController(): void {
    this.typeaheadController = new TypeaheadController(() => {
      return {
        getItems: () => this.items.toArray(),
        typeaheadBufferTime: this.typeaheadDelay,
        active: this.typeaheadActive,
      };
    });
  }

  private _getAnchorElement() {
    if (!this.anchor) throw new Error('Anchor is required');

    if ('nativeElement' in this.anchor) return this.anchor.nativeElement;
    else if ('elementRef' in this.anchor) return this.anchor.elementRef.nativeElement;
    else return this.anchor;
  }
  private _getSurfaceAnchorElement() {
    const anchorElement = this._getAnchorElement();
    if (this.anchorSelector) return anchorElement.querySelector(this.anchorSelector);
    return anchorElement;
  }
}
